import { RestaurantSettings } from '@interfaces/restaurant-settings.interface';
import { RestaurantTag } from '@interfaces/restaurant-tag.interface';
import { AttributeGroup } from '@models/attribute-group.model';
import { Address } from './address.model';
import { Dish } from './dish.model';
import { FoodCategory } from './food-category.model';

/**
 * Represents a restaurant.
 */
export class Restaurant {
  id?: number;
  name?: string;
  hostname?: string;
  age_restricted?: number;
  open?: boolean;
  orderMinimalPrice?: string;
  orderLowestDeliveryPrice?: string;
  lat?: number;
  lng?: number;
  distance?: string;
  isDeliveryActive?: boolean;
  average_price?: number;
  food_category?: FoodCategory[];
  dishes?: Dish[];
  settings?: RestaurantSettings[];
  logo?: string;
  bg_image?: string;
  dish_default_image?: string;
  restaurantTags?: RestaurantTag[];
  address?: Address;
  isPreview?: boolean;
  attribute_groups?: AttributeGroup[];
  table_reservation_active?: boolean;
  createdAt!: string;

  /**
   * Creates an instance of the Restaurant class.
   * @param data - The data to initialize the restaurant.
   */
  constructor(data: Restaurant) {
    Object.assign(this, data);

    if (data.food_category) {
      this.food_category = data.food_category.map((category: FoodCategory) => new FoodCategory(category));
    }
    if (data.dishes) {
      this.dishes = data.dishes.map((dish: Dish) => new Dish(dish));
    }
    if (data.attribute_groups) {
      this.attribute_groups = data.attribute_groups.map((group: AttributeGroup) => new AttributeGroup(group));
    }
  }
}
