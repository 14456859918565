export class Attribute {
  attribute_group_id!: number | null;
  description?: string;
  icon?: string;
  id!: number;
  key!: string;
  name!: string;
  selected!: boolean;
  isCheckbox?: number;

  constructor(data: Attribute | null) {
    Object.assign(this, data);
  }
}
