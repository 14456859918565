import { Injectable, inject } from '@angular/core';
import { LayoutSettings } from '@configs/theme.config';
import { LayoutState } from '@interfaces/layout-state.interface';
import { BottomMenuPage } from '@modules/mobile-bottom-menu/mobile-bottom-menu.component';
import { PlatformService } from './platform.service';
import { EventDataType } from '@enums/event-data-type.enum';
import { EventBusService } from '@services/event-bus.service';

/**
 * CSS class name used to disable scrolling on the body element.
 */
const BODY_LOCKED_SCROLL_CLASS = 'scroll-disabled';

@Injectable({ providedIn: 'root' })
/**
 * Service responsible for managing the layout state of the application.
 */
export class LayoutService {
  /**
   * Represents the platform service used for detecting the current platform.
   */
  private readonly platform = inject(PlatformService);

  private readonly eventBusService = inject(EventBusService);
  /**
   * Represents the layout state of the application.
   */
  readonly layoutState: LayoutState = LayoutSettings.LAYOUT_STATE;

  /**
   * Toggles the visibility of the sidebar.
   */
  public onSidebarToggle(): void {
    this.layoutState.sidebarActive = !this.layoutState.sidebarActive;
    this.layoutState.sidebarActive ? this.disableBodyScroll() : this.enableBodyScroll();
  }

  /**
   * Hides all sidebars.
   */
  public hideAllSidebars(): void {
    this.layoutState.sidebarCartActive.set(false);
    this.layoutState.sidebarActive = false;
    this.enableBodyScroll();
  }

  /**
   * Toggles the cart sidebar visibility.
   */
  public onCartSidebarToggle(): void {
    this.layoutState.sidebarCartActive.set(!this.layoutState.sidebarCartActive());
    this.layoutState.sidebarCartActive() ? this.disableBodyScroll() : this.enableBodyScroll();
  }

  /**
   * Sets the visibility of the mobile bottom menu.
   *
   * @param value - A boolean value indicating whether the mobile bottom menu should be displayed or not.
   */
  public displayMobileBottomMenu(value: boolean): void {
    this.layoutState.bottomMenuVisible.set(value);
  }

  /**
   * Sets the visibility of the topbar search.
   * @param value - The visibility value.
   */
  public setTopbarSearchVisibility(value: boolean): void {
    this.layoutState.topbarSearchVisible = value;
  }

  /**
   * Sets the visibility of the topbar address.
   * @param value - The visibility value.
   */
  public setTopbarAddressVisibility(value: boolean): void {
    this.layoutState.topbarAddressVisible = value;
  }

  /**
   * Sets the visibility of the topbar cart button.
   * @param value - The visibility value.
   */
  public setTopbarCartButtonVisibility(value: boolean): void {
    this.layoutState.topbarCartButtonVisibility = value;
  }

  /**
   * Sets the style of the topbar cart button.
   * @param value - The style value.
   */
  public setTopbarCartButtonStyle(value: boolean): void {
    this.layoutState.topbarCartButtonIconOnly = value;
  }

  /**
   * Sets the visibility of the topbar filters button.
   *
   * @param value - A boolean value indicating whether the filters button should be visible or not.
   */
  public setTopbarFiltersButtonVisibility(value: boolean): void {
    this.layoutState.filtersButtonVisible = value;
  }

  /**
   * Sets the visibility of the mobile cart button.
   *
   * @param value - A boolean value indicating whether the mobile cart button should be visible or not.
   */
  public setMobileCartButtonVisibility(value: boolean): void {
    this.layoutState.cartMobileVisible.set(value);
  }

  public setAssistantMobileButtonVisibility(value: boolean): void {
    this.layoutState.assistantMobileVisible.set(value);
  }

  /**
   * Sets the mobile content page and makes it visible.
   * @param page - The page to be set as the mobile content page.
   */
  public openMobileContentPage(page: BottomMenuPage): void {
    this.layoutState.mobileContentPage.set(page);
    this.layoutState.mobileContentPageVisible.set(true);
    this.eventBusService.emit({ name: EventDataType.OPEN_MOBILE_CART_PAGE, value: page });
    this.disableBodyScroll();
  }

  /**
   * Closes the mobile content page and updates the layout state.
   */
  public closeMobileContentPage(): void {
    this.layoutState.mobileContentPage.set(BottomMenuPage.RESTAURANTS);
    this.layoutState.mobileContentPageVisible.set(false);
    this.enableBodyScroll();
  }

  /**
   * Disables the scrolling of the body element.
   */
  public disableBodyScroll(): void {
    if (this.platform.isPlatformBrowser()) {
      document.body.classList.add(BODY_LOCKED_SCROLL_CLASS);
    }
  }

  /**
   * Enables scrolling on the body element.
   */
  public enableBodyScroll(): void {
    if (this.platform.isPlatformBrowser()) {
      document.body.classList.remove(BODY_LOCKED_SCROLL_CLASS);
    }
  }
}
