import { FoodCategoryVisibility } from '@enums/food-category-visibility.enum';
import { Dish } from './dish.model';

/**
 * Represents a food category.
 */
export class FoodCategory {
  /**
   * The ID of the food category.
   */
  id?: number;

  /**
   * The name of the food category.
   */
  name?: string;

  /**
   * The position of the food category.
   */
  position?: number;

  /**
   * The parent ID of the food category.
   */
  parent_id?: number | null;

  /**
   * The description of the food category.
   */
  description?: string;

  /**
   * The visibility of the food category.
   */
  visibility?: FoodCategoryVisibility;

  /**
   * The children dishes of the food category.
   */
  children?: Dish[];

  /**
   * Indicates whether the food category is a bundle.
   */
  isBundle?: boolean;

  /**
   * Represents the price of a food category.
   */
  price?: string;

  /**
   * Creates an instance of FoodCategory.
   * @param data - The data to initialize the food category.
   */
  constructor(data: FoodCategory) {
    Object.assign(this, data);
  }
}
