/**
 * Creates an instance of HttpParams based on the provided params object.
 *
 * @param paramsObj - The object containing the key-value pairs for the parameters.
 * @returns An instance of HttpParams with the provided parameters.
 */
import { HttpParams } from '@angular/common/http';

export function CreateHttpParams(paramsObj: Record<string, any>): HttpParams {
  let params = new HttpParams();

  for (const key in paramsObj) {
    if (paramsObj.hasOwnProperty(key)) {
      const value = paramsObj[key];
      params = params.set(key, value);
    }
  }

  return params;
}
