import { AttributeGroupImportance } from '@enums/attribute-group-importance.enum';
import { AttributeInputType } from '@enums/attribute-input-type.enum';
import { Attribute } from './attribute.model';

export class AttributeGroup {
  is_primary!: AttributeGroupImportance;
  input_type!: AttributeInputType;
  attributes: Attribute[];
  description?: string;
  id!: number;
  key!: string;
  name!: string;
  showMore?: boolean;

  constructor(data: AttributeGroup) {
    Object.assign(this, data);
    this.attributes = data.attributes.map(attribute => new Attribute(attribute));
  }
}
