import { signal } from '@angular/core';
import { LayoutState } from '@interfaces/layout-state.interface';
import { BottomMenuPage } from '@modules/mobile-bottom-menu/mobile-bottom-menu.component';

/**
 * Represents the theme configuration for the application.
 */
export class ThemeConfig {
  /**
   * Represents the default dialog settings.
   */
  static readonly DEFAULT_CONFIRM_DIALOG_STYLES = {
    maxWidth: '630px',
    width: '100%',
    margin: '0 15px',
  };
}

/**
 * Represents the layout settings for the application.
 */
export class LayoutSettings {
  /**
   * Represents the state of the layout.
   */
  static readonly LAYOUT_STATE: LayoutState = {
    sidebarActive: false,
    sidebarCartActive: signal(false),
    topbarSearchVisible: false,
    topbarAddressVisible: false,
    topbarCartButtonVisibility: false,
    topbarCartButtonIconOnly: true,
    mobileContentPageVisible: signal(false),
    mobileContentPage: signal(BottomMenuPage.RESTAURANTS),
    filtersButtonVisible: false,
    bottomMenuVisible: signal(false),
    cartMobileVisible: signal(false),
    assistantMobileVisible: signal(false),
  };
}
