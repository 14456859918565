import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { environment } from '@env';
import { GetRestaurantUrl } from '@functions/get-restaurant-url.function';
import { BookTableInput } from '@interfaces/inputs/book-table-input.interface';
import { FilterDishesInput } from '@interfaces/inputs/filter-dishes-input.interface';
import { DataResponse } from '@interfaces/responses/data-response.interface';
import { Dish } from '@models/dish.model';
import { Reservation } from '@models/reservation.model';
import { Restaurant } from '@models/restaurant.model';
import { Observable, map } from 'rxjs';

/**
 * Service for retrieving restaurants from the API.
 */
@Injectable({ providedIn: 'root' })
export class RestaurantsService {
  /**
   * The HTTP client used for making HTTP requests.
   */
  private readonly http = inject(HttpClient);

  /**
   * Retrieves a list of restaurants from the API.
   * @param params - Optional HTTP parameters for the request.
   * @returns An Observable that emits a DataResponse containing an array of Restaurant objects.
   */
  getRestaurants(params: HttpParams = new HttpParams()): Observable<DataResponse<Restaurant[]>> {
    return this.http
      .get(environment.api + 'restaurants', { params })
      .pipe(map(response => response as DataResponse<Restaurant[]>));
  }

  /**
   * Retrieves the details of a restaurant.
   *
   * @param id - The ID of the restaurant.
   * @param params - Optional HTTP parameters for the request.
   * @returns An Observable that emits the restaurant details.
   */
  getRestaurantDetails(hostname: string, params: HttpParams = new HttpParams()): Observable<DataResponse<Restaurant>> {
    return this.http
      .get(environment.api + `restaurants/${hostname}`, { params })
      .pipe(map(response => response as DataResponse<Restaurant>));
  }

  /**
   * Books a table for a given restaurant.
   *
   * @param input - The input data for booking the table.
   * @param restaurant - The name or ID of the restaurant.
   * @returns An Observable that emits a DataResponse containing the reservation details.
   */
  bookTable(input: BookTableInput, restaurant: string): Observable<DataResponse<Reservation>> {
    return this.http
      .post(GetRestaurantUrl(`reservations`, restaurant), input)
      .pipe(map(response => response as DataResponse<Reservation>));
  }

  /**
   * Retrieves the details of a dish from a specific restaurant.
   * @param restaurant - The name of the restaurant.
   * @param id - The ID of the dish.
   * @returns An Observable that emits a DataResponse containing the dish details.
   */
  getDishDetails(
    restaurant: string,
    id: string,
    params: HttpParams = new HttpParams()
  ): Observable<DataResponse<Dish>> {
    return this.http
      .get(GetRestaurantUrl(`dishes/${id}`, restaurant), { params })
      .pipe(map(response => response as DataResponse<Dish>));
  }

  /**
   * Filters dishes based on the provided input and restaurant.
   * @param input - The input for filtering dishes.
   * @param restaurant - The restaurant to filter dishes for.
   * @returns An Observable that emits a DataResponse containing an array of Dish objects.
   */
  filterDishes(input: FilterDishesInput, restaurant: string, params: HttpParams = new HttpParams()): Observable<DataResponse<Dish[]>> {
    return this.http
      .post(GetRestaurantUrl(`filter_dishes`, restaurant), input, { params })
      .pipe(map(response => response as DataResponse<Dish[]>));
  }
}
