<div class="bottom-menu">
  @for (item of menuItems; track $index) {
    @if (item.mobileVisible) {
      <div class="bottom-menu-item" [ngClass]="item.class" pRipple (click)="doAction(item)">
        @if (item.cart) {
          <span class="bottom-menu-item-cart">
            @if (counter() > 0) {
              <span class="cart-counter">{{ counter() }}</span>
            }
            <svg-icon
              class="icon-bottom-menu"
              [ngClass]="{ 'is-active': item.active }"
              [src]="'assets/icons/' + item.icon"></svg-icon>
          </span>
        } @else {
          <svg-icon
            class="icon-bottom-menu"
            [ngClass]="{ 'is-active': item.active }"
            [src]="'assets/icons/' + item.icon"></svg-icon>
        }
      </div>
    }
  }
</div>
