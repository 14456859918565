import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, computed, effect, inject } from '@angular/core';
import { RouterModule } from '@angular/router';
import { EventDataType } from '@enums/event-data-type.enum';
import { CartService } from '@services/cart.service';
import { EventBusService } from '@services/event-bus.service';
import { LayoutService } from '@services/layout.service';
import { SvgIconComponent } from 'angular-svg-icon';
import { RippleModule } from 'primeng/ripple';

/**
 * Enum representing the pages of the bottom menu.
 */
export enum BottomMenuPage {
  RESTAURANTS = 'restaurants',
  SEARCH = 'search',
  CART = 'cart',
  BOT = 'bot',
  FILTERS = 'filters',
}

/**
 * Interface representing a bottom menu item.
 */
export interface BottomMenuItem {
  icon: string;
  page: BottomMenuPage;
  active: boolean;
  mobileVisible: boolean;
  cart?: boolean;
  class?: string;
}

/**
 * Array of PrimeNG modules used in the mobile-bottom-menu component.
 */
const PRIME_NG_MODULES = [RippleModule];

/**
 * Component representing the mobile bottom menu.
 */
@Component({
  selector: 'app-mobile-bottom-menu',
  standalone: true,
  imports: [PRIME_NG_MODULES, RouterModule, SvgIconComponent, NgClass],
  templateUrl: './mobile-bottom-menu.component.html',
  styleUrl: './mobile-bottom-menu.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MobileBottomMenuComponent {
  /**
   * Service responsible for managing the layout of the mobile bottom menu.
   */
  private readonly layoutService = inject(LayoutService);
  /**
   * Service for managing the cart.
   */
  private readonly cartService = inject(CartService);
  /**
   * Service for handling events in the application.
   */
  private readonly eventBusService = inject(EventBusService);
  /**
   * Reference to the ChangeDetectorRef instance.
   */
  private readonly cd = inject(ChangeDetectorRef);

  /**
   * Indicates whether the cart is visible.
   */
  isCartVisible = computed(() => this.layoutService.layoutState.cartMobileVisible());

  isAssistantVisible = computed(() => this.layoutService.layoutState.assistantMobileVisible());

  /**
   * The computed property that returns the counter value from the cart service.
   */
  counter = computed(() => this.cartService.counter());

  /**
   * Array of menu items.
   */
  readonly menuItems: BottomMenuItem[] = [
    {
      icon: 'fork.svg',
      page: BottomMenuPage.RESTAURANTS,
      active: true,
      mobileVisible: true,
    },
    {
      icon: 'search-lg.svg',
      page: BottomMenuPage.SEARCH,
      active: false,
      mobileVisible: true,
      class: 'bottom-menu-item-search',
    },
    {
      icon: 'cart.svg',
      page: BottomMenuPage.CART,
      active: false,
      mobileVisible: false,
      cart: true,
    },
    {
      icon: 'bot.svg',
      page: BottomMenuPage.BOT,
      active: false,
      mobileVisible: false,
    },
  ];

  constructor() {
    effect(() => {
      this.menuItems.forEach((menuItem: BottomMenuItem) => {
        switch (menuItem.page) {
          case BottomMenuPage.CART:
            menuItem.mobileVisible = this.isCartVisible();
            break;
          case BottomMenuPage.BOT:
            menuItem.mobileVisible = this.isAssistantVisible();
            break;
          default:
            break;
        }
      });
      this.cd.detectChanges();
    });
  }

  /**
   * Performs an action based on the selected menu item.
   * @param item The selected menu item.
   */
  doAction(item: BottomMenuItem): void {
    switch (item.page) {
      case BottomMenuPage.RESTAURANTS:
        this.layoutService.closeMobileContentPage();
        break;
      case BottomMenuPage.SEARCH:
        this.eventBusService.emit({ name: EventDataType.OPEN_RESTAURANT_SEARCH_DIALOG, value: true });
        break;
      case BottomMenuPage.CART:
        this.layoutService.openMobileContentPage(BottomMenuPage.CART);
        break;
      case BottomMenuPage.BOT:
        this.eventBusService.emit({ name: EventDataType.OPEN_ASSISTANT, value: true });
        break;
      default:
        break;
    }

    this.menuItems.forEach((menuItem: BottomMenuItem) => {
      menuItem.active = menuItem.page === item.page;
    });
  }
}
